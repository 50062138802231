"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
class DiscountcalculatorCtrl {
    constructor($scope, $filter, ConfigService, actionService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.type = localStorage.getItem(`${this.constructor.name}_type`) || 'off';
        this.values = {};
        this.result = undefined;
    }
    $onInit() {
        this.$scope.$watch('$ctrl.type', (type) => {
            localStorage.setItem(`${this.constructor.name}_type`, type);
        });
        // this.ConfigService.settings$.pipe(
        //     skip(1),
        //     pluck('decimal_places'),
        //     distinctUntilChanged(),
        //     tap(() => {
        //         if (this.discountForm)
        //             this.submitForm(this.discountForm)
        //     })
        // ).subscribe()
    }
    submitForm($form) {
        let you_save;
        let you_pay;
        let min_original_price;
        if ($form.$valid) {
            let result = {};
            switch (this.type) {
                case "off":
                    you_save = (this.values.discount * this.values.original_price / 100);
                    result = {
                        you_pay: this.values.original_price - you_save,
                        you_save: you_save
                    };
                    break;
                case "off_2":
                    min_original_price = Math.min(this.values.product1, this.values.product2);
                    you_save = (this.values.discount * min_original_price / 100);
                    you_pay = (this.values.product1 + this.values.product2) - you_save;
                    result = {
                        you_pay: you_pay,
                        avg_product_price: you_pay / 2,
                        you_save: you_save,
                        percent_of_price: you_save * 100 / (this.values.product1 + this.values.product2),
                    };
                    break;
                case "off_3":
                    min_original_price = Math.min(this.values.product1, this.values.product2, this.values.product3);
                    you_save = (this.values.discount * min_original_price / 100);
                    you_pay = (this.values.product1 + this.values.product2 + this.values.product3) - you_save;
                    result = {
                        you_pay: you_pay,
                        avg_product_price: you_pay / 3,
                        you_save: you_save,
                        percent_of_price: you_save * 100 / (this.values.product1 + this.values.product2 + this.values.product3),
                    };
                    break;
                case "fixed":
                    result = {
                        you_pay: this.values.original_price - this.values.discount_price,
                        you_save: this.values.discount_price,
                        percent_of_price: (this.values.discount_price * 100 / this.values.original_price),
                    };
                    break;
                case "2for1":
                    you_pay = Math.max(this.values.product1, this.values.product2);
                    result = {
                        you_pay: you_pay,
                        avg_product_price: you_pay / 2,
                        you_save: (this.values.product1 + this.values.product2) - you_pay,
                        percent_of_price: 100 - you_pay * 100 / (this.values.product1 + this.values.product2),
                    };
                    break;
                case "3for2":
                    you_pay = (this.values.product1 + this.values.product2 + this.values.product3) - Math.min(this.values.product1, this.values.product2, this.values.product3);
                    result = {
                        you_pay: you_pay,
                        avg_product_price: you_pay / 3,
                        you_save: (this.values.product1 + this.values.product2 + this.values.product3) - you_pay,
                        percent_of_price: 100 - you_pay * 100 / (this.values.product1 + this.values.product2 + this.values.product3),
                    };
                    break;
                case "double":
                    you_pay = this.values.original_price - this.values.discount1 * this.values.original_price / 100;
                    you_pay = (you_pay - this.values.discount2 * you_pay / 100);
                    result = {
                        you_pay: you_pay,
                        you_save: this.values.original_price - you_pay,
                    };
                    break;
                case "triple":
                    you_pay = this.values.original_price - this.values.discount1 * this.values.original_price / 100;
                    you_pay = (you_pay - this.values.discount2 * you_pay / 100);
                    you_pay = (you_pay - this.values.discount3 * you_pay / 100);
                    result = {
                        you_pay: you_pay,
                        you_save: this.values.original_price - you_pay,
                    };
                    break;
                case "multiple":
                    result = {
                        you_pay: this.values.offer_price,
                        you_save: this.values.original_price * this.values.num_of_products - this.values.offer_price,
                        percent_of_price: 100 - this.values.offer_price * 100 / (this.values.original_price * this.values.num_of_products)
                    };
                    break;
            }
            this.result = {};
            for (let key in result) {
                if (result[key] == Math.ceil(result[key])) {
                    this.result[key] = this.$filter('number')(result[key], 0);
                }
                else {
                    this.result[key] = this.$filter('number')(result[key], 10);
                }
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.values = {};
        this.result = undefined;
    }
    copy(id, value) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), value);
        }
    }
    setType(type) {
        this.type = type;
        if (this.discountForm)
            this.clear(this.discountForm);
    }
}
DiscountcalculatorCtrl.$inject = ['$scope', '$filter', 'ConfigService', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameDiscountcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: DiscountcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('discountcalculator/');
        ConfigServiceProvider.setDefaultConfig({
        // decimal_places: 2
        });
    }]);
